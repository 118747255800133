<template>
  <div id="app-layout" :class="{ 'sidebar-is-open': sidebarIsOpen }">
    <ProgressUI :progress="progressBarValue" class="is-sticky" />
    <RouterView name="LeftSidebar" class="app-sidebar" />
    <div class="app-content">
      <div id="view">
        <TopBar v-if="LeftSidebar && loggedIn" />
        <RouterView v-slot="{ Component }">
          <Transition name="page-fade">
            <component :is="Component" />
          </Transition>
        </RouterView>
      </div>
    </div>
    <div class="app-mobile">
      <MobileAlert />
    </div>
    <Transition name="overlay">
      <AppOverlay v-if="showSidebarOverlay" @click="closeSidebar" />
    </Transition>
    <AppSpinner />
    <AdminMode v-if="isImpersonate" class="admin-mode" :label="$t('route.admin_impersonate')" />
  </div>
</template>
<script>
import { RouterView } from "vue-router";
import { useBreakpoints } from "@/helpers/utils/useBreakpoints";
import MobileAlert from "@/components/MobileAlert.vue";

import { useAdminAuthStore, useAuthStore, useBreakpointStore, useNavStore, useNotificationStore } from "@/stores";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import ProgressUI from "@/components/ProgressUI.vue";
import AppOverlay from "@/components/AppOverlay.vue";
import AppSpinner from "@/components/AppSpinner.vue";
import TopBar from "@/components/TopBar.vue";
import AdminMode from "@/components/AdminMode.vue";

export default {
  setup() {
    useBreakpoints();
    const navStore = useNavStore();
    const notificationStore = useNotificationStore();
    let { sidebarIsOpen } = storeToRefs(navStore);
    const breakpointStore = useBreakpointStore();

    const { alerts } = storeToRefs(notificationStore);
    useNotificationStore().resetAlerts();

    const progressBarValue = computed(() => useNavStore().getProgressBarValue);

    return {
      sidebarIsOpen,
      breakpointStore,
      alerts,
      progressBarValue,
    };
  },
  components: {
    TopBar,
    AppSpinner,
    AppOverlay,
    ProgressUI,
    RouterView,
    MobileAlert,
    AdminMode,
  },
  methods: {
    closeSidebar() {
      useNavStore().sidebarClose();
    },
  },
  computed: {
    LeftSidebar() {
      const router = this.$router;
      const LeftSidebar = router?.currentRoute?.value?.matched[0]?.components?.LeftSidebar;
      return !!LeftSidebar;
    },
    isImpersonate() {
      return useNavStore().isNoAdminPageAndImpersonateIsActive;
    },
    loggedIn() {
      const navStore = useNavStore();
      const authStore = useAuthStore();
      const adminAuthStore = useAdminAuthStore();
      return navStore.isAdminPage ? adminAuthStore.loggedIn : authStore.loggedIn;
    },
    showSidebarOverlay() {
      return this.sidebarIsOpen && this.breakpointStore.type != "xl" ? true : false;
    },
  },
  mounted() {
    const authStore = useAuthStore();
    authStore.startExpirationValidation();
  },
  watch: {
    alerts: {
      handler(alerts) {
        useNotificationStore().showAlerts(alerts);
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.overlay-enter-active {
  transition: all 0.28s ease-out;
}

.overlay-leave-active {
  transition: all 0.28s cubic-bezier(1, 0.5, 0.8, 1);
}

.overlay-enter-from,
.overlay-leave-to {
  opacity: 0;
}

.admin-mode {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
}
</style>
